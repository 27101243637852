import { OpenableEntity } from "./OpenableEntity";

export class ChestEntity extends OpenableEntity {
    hasTablet: boolean = true;

    open(): string[] {
        if (this.opened) {
            return super.open();
        } else {
            if (this.hasTablet) {
                this._game.entities.TABLET?.take();
                this.hasTablet = true;

                return [
                    ...super.open(),
                    ...this._("open_with_tablet"),
                ];
            } else {
                return super.open();
            }
        }
    }
}