import { Entity } from "./Entity";

// Entity baseclasses
// ==================

export abstract class OpenableEntity extends Entity {
    baseIds: string[] = ["OPENABLE_ENTITY", "ENTITY"];
    opened: boolean = false;

    open(): string[] {
        if (this.opened) {
            return this._("open_already_opened");
        } else {
            this.opened = true;
            return this._("open");
        }
    }

    close(): string[] {
        if (!this.opened) {
            return this._("close_already_closed");
        } else {
            this.opened = false;
            return this._("close");
        }
    }
}
