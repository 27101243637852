import { Entity } from "./Entity";

export class SandEntity extends Entity {
    useOn(other: Entity): string[] {
        if (this._game.currentLocation.id === "CELLAR" && other.id === "STAIRS") {
            this.store();
            return this._("use_on_steps");
        }
        return super.use();
    }
}
