import { Entity } from "./Entity";

export class ShovelEntity extends Entity {
    dugUpChest: boolean = false

    useOn(other: Entity): string[] {
        if (other.id === "DIRT") {
            // Place the dirt in your inventory
            this._game.entities.SAND?.take();
            return this._("use_dig_up_sand");
        }

        if (other.id === "GROUND") {
            if (!this.dugUpChest) {
                this.dugUpChest = true;
                this._game.currentLocation.store(this._game.entities.CHEST!);
                return this._("use_dig_up_chest");
            } else {
                return this._("use_dig_up_chest_again");
            }
        }

        return super.use();
    }
}
