import { Entity } from "./Entity";
import { VacuumEntity } from "./VacuumEntity";

export class BatteryEntity extends Entity {
    useOn(other: Entity): string[] {
        // Try to power up
        if (other.id === "VACUUM") {
            const vacuum = other as VacuumEntity;
            vacuum.powered = true;
            this.discard();
            return this._("use_on_battery", "VACUUM");
        }

        return super.useOn(other);
    }
}
