import { Direction, directionFromString } from "../Direction";
import { Game } from "../Game";
import { Entity } from "../entity/Entity";
import { LocationName } from "./Locations";
import mustache from 'mustache';

import entityDefinitions from '../entity/Entities.json';
import { NamedEntity } from "../entity/Entities";

export class Location {
    protected _id: LocationName;
    protected _name: string;
    protected _description: string;
    protected _definitions?: LocationDefinitions;
    protected _game: Game;
    protected _connections: {[key in Direction]?: LocationName} = {};
    protected _contents: Map<string, Entity> = new Map<string, Entity>();
    protected _strings: Record<string, string> = {};

    visited: boolean = false;
    visitable: boolean = true;

    constructor (id: LocationName, game: Game, defs?: LocationDefinitions) {
        this._id = id;
        this._name = id;
        this._description = id;

        if (defs && id in defs) {
            const def = defs[id];
            this._name = defs[id]?.name || this._name;
            this._description = defs[id]?.description || this._description;
            this.visitable = defs[id]?.visitable || this.visitable;

            // Establish connections to other locations
            for (const [d, next] of Object.entries(def.connections)) {
                this.connect(directionFromString(d), next as LocationName);
            }

            // Prefix all prop entities with "__" to ensure they don't conflict with the
            // named entities used for solving puzzles and progressing through the game
            const registerProp = (name: string, aliases: string[], description: string, def: EntityDefinition = {}): void => {
                const nameWithPrefix = `__${name.toUpperCase()}`;
                const aliasesWithPrefix = aliases.map((a) => `__${a.toUpperCase()}`);

                const entity = new Entity(nameWithPrefix, game, {...entityDefinitions, [nameWithPrefix]: def});
                entity.name = name.toLowerCase();
                entity.aliases = aliasesWithPrefix;
                entity.description = description;
                entity.storable = true;

                this.store(entity);
            }

            // Assign props/entities to location
            for (const e of (def.entities || [])) {
                if (typeof e === "string") {
                    // Named entity
                    if (Object.keys(game.entities).includes(e)) {
                        const namedEntity = game.entities[e as NamedEntity];
                        this.store(namedEntity!);
                    } else {
                        throw new Error("Couldn't find entity with id " + e);
                    }
                } else {
                    for (const [propNames, propDesc] of Object.entries(e)) {
                        const [propName, ...aliases] = propNames.split("|");
                        if (typeof propDesc === "string") {
                            registerProp(propName, aliases, propDesc);
                        } else {
                            const propDef = propDesc as EntityDefinition;
                            registerProp(propName, aliases, propDef.description || "", propDef);
                        }
                    }
                }
            }
        }

        this._definitions = defs;
        this._game = game;
    }

    public get id(): LocationName {
        return this._id;
    }

    public get name(): string {
        return mustache.render(this._name, this._game.context());
    }

    public set name(value: string) {
        this._name = value;
    }

    public get description(): string {
        return mustache.render(this._description, this._game.context());
    }

    public set description(value: string) {
        this._description = value;
    }

    public get strings(): Record<string, string> {
        return this._strings;
    }

    public get connections(): {[key in Direction]?: LocationName} {
        return this._connections;
    }

    public get contents(): Map<string, Entity> {
        return this._contents;
    }

    public set strings(value: Record<string, string>) {
        this._strings = value;
    }

    enter(): void {
        this.visited = true;
        this._game.currentLocation = this;
    }

    canEnter(d: Direction, from: Location): string[] {
        return [];
    }

    onEnter(d: Direction, from: Location): string[] {
        return [];
    }

    canLeave(d: Direction, to: Location): string[] {
        return [];
    }

    onLeave(d: Direction, to: Location): string[] {
        return [];
    }

    connect(d: Direction, locationName: LocationName): void {
        this._connections[d] = locationName;
    }

    disconnect(d: Direction): void {
        delete this._connections[d];
    }

    store(e: Entity): void {
        if (e.storable) {
            for (const id of [e.id, ...e.aliases]) {
                this._contents.set(id, e);
            }
        } else {
            throw new Error(`Can't store/leave ${e.id} at this location.`)
        }
    }

    get(id: string): Entity | undefined {
        return this._contents.get(id);
    }

    has(id: string): boolean {
        return this._contents.has(id);
    }

    remove(id: string): Entity | undefined {
        const removed = this.get(id);
        if (removed?.removable) {
            for (const _id of [id, ...removed.aliases]) {
                this._contents.delete(_id);
            }
            return removed;
        } else {
            throw new Error(`Can't remove ${id} from this location.`)
        }
    }

    connectionExists(d: Direction): boolean {
        return d in this._connections;
    }

    connection(d: Direction): LocationName | undefined {
        return this._connections[d];
    }

    protected _(key: string, extraOrOverrideId?: Record<string, any> | string, overrideId?: string): string[] {
        const extra = (typeof extraOrOverrideId !== "string" && extraOrOverrideId) || {};
        const resolvedId = (typeof extraOrOverrideId === "string" && extraOrOverrideId) || overrideId || this._id;

        const strings: Record<string, string> = (this._definitions && this._definitions[resolvedId as LocationName]?.strings) || {};
        const stringExists: boolean = strings && key in strings;

        if (!stringExists) {
            throw new Error(`Missing string. Tried looking up: ${resolvedId}.strings.${key}`);
        }

        const tpl: string = (strings && strings[key]) || key;
        return [mustache.render(tpl, this._game.context(extra))];
    }
}