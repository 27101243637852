import { Entity } from "./Entity";
import { PhoneEntity } from "./PhoneEntity";


export class PhoneNumberEntity extends Entity {

    phoneNumber: string = "000-000-0000";

    useOn(other: Entity): string[] {
        if (other.id === "PHONE") {
            const phone = other as PhoneEntity;
            return phone.dial(this.phoneNumber);
        } else {
            return super.useOn(other);
        }
    }
}
