import { Direction, Directions } from "./Direction";
import { Entity } from "./entity/Entity";
import { Location } from "./location/Location";
import { Command, GO, LOOK, INVENTORY, USE, TAKE, PROGRESS, OPEN, CLOSE, SANITY } from './Command';
import { initializeEntities } from "./entity/Entities";
import { initializeLocations } from "./location/Locations";

import moment from 'moment';

import strings from './Strings.json'
import mustache from 'mustache';
import { PhoneEntity } from "./entity/PhoneEntity";
import { SafeEntity } from "./entity/SafeEntity";
type StringsKey = keyof typeof strings["GAME"];

function join(conjunction: string, ...parts: string[]): string {
    if (parts.length > 2) {
        const lastIdx = parts.length - 1;
        const last = parts[lastIdx];
        return parts.slice(0, lastIdx).join(", ") + `, ${conjunction} ${last}`;
    } else {
        return parts.join(` ${conjunction} `);
    }
}

type Message = {
    preserve: boolean;
    message: string[];
}

const enum SanityLevel {
    // 300
    SANE = "SANE",
    // 250
    WANING = "WANING",
    // 225
    FADING = "FADING",
    // 200
    DIMINISHED = "DIMINISHED",
    // 175
    FLEETING = "FLEETING",
    // 150
    COMPROMISED = "COMPROMISED",
    // 125
    NEUROTIC = "NEUROTIC",
    // 100
    MANIC = "MANIC",
    // 80
    SENSELESS = "SENSELESS",
    // 60
    DELUSIONAL = "DELUSIONAL",
    // 40
    DERANGED = "DERANGED",
    // 20
    UNHINGED = "UNHINGED",
}

export const MAX_SANITY = 750;

export class Game {
    // Game state
    private _entities = initializeEntities(this);
    private _locations = initializeLocations(this, this._entities);

    // Player state
    private _startedAt = moment();
    private _gameOver: boolean = false
    private _gameWon: boolean = false
    private _sanity: number = MAX_SANITY;
    private _hallucinating: boolean = false;
    private _steps: number = 0;
    private _currentLocation: Location;
    private _inventory: Map<string, Entity> = new Map<string, Entity>();
    private _fragmentsFound: number = 0;

    // Special states based on player interactions with the world
    // Might also use these for scoring
    slippedOnStairs: boolean = false;
    foundCellarLadder: boolean = false;
    fallenFromSpookyRoom: boolean = false;
    attackedByGhosts: boolean = false;
    attackedByBats: boolean = false;
    foundTreehouse: boolean = false;
    attackCount: number = 0;

    // Enable debugging
    debug: boolean = false;

    constructor() {
        // Setup initial player inventory
        this._entities["IPHONE"]?.take();
        this._entities["CAMERA"]?.take();
        this._entities["FLASHLIGHT"]?.take();

        // Setup initial starting location
        this._currentLocation = this._locations["TWISTED_RAILINGS"]!;
        this._currentLocation.visited = true;
    }

    public get entities() {
        return this._entities;
    }

    public get inventory() {
        return this._inventory;
    }

    public get locations() {
        return this._locations;
    }

    public get gameOver(): boolean {
        return this._gameOver;
    }

    public get hallucinating(): boolean {
        return this._hallucinating;
    }

    public set hallucinating(value: boolean) {
        this._hallucinating = value;
    }

    public get steps(): number {
        return this._steps;
    }

    public get sanity(): number {
        return this._sanity;
    }

    public get sanityLevel(): string {
        let level = SanityLevel.UNHINGED;

        if (this._sanity >= 400) {
            level = SanityLevel.SANE;
        } else if (this._sanity >= 350) {
            level = SanityLevel.WANING;
        } else if (this._sanity >= 300) {
            level = SanityLevel.FADING;
        } else if (this._sanity >= 250) {
            level = SanityLevel.FLEETING;
        } else if (this._sanity >= 200) {
            level = SanityLevel.DIMINISHED;
        } else if (this._sanity >= 150) {
            level = SanityLevel.COMPROMISED;
        } else if (this._sanity >= 100) {
            level = SanityLevel.NEUROTIC;
        } else if (this._sanity >=  80) {
            level = SanityLevel.MANIC;
        } else if (this._sanity >=  60) {
            level = SanityLevel.SENSELESS;
        } else if (this._sanity >=  40) {
            level = SanityLevel.DELUSIONAL;
        } else if (this._sanity >=  20) {
            level = SanityLevel.DERANGED;
        }

        return (strings && strings["GAME"][level]) || level;
    }

    public get phoneCallsMade(): number {
        const phone = this._entities?.PHONE as (PhoneEntity | undefined);
        return (phone && phone.numberCalls) || 0;
    }

    public get safeCrackingAttempts(): number {
        const safe = this._entities?.SAFE as (SafeEntity | undefined);
        return (safe && safe.numberAttempts) || 0;
    }

    public get locationsVisited(): number {
        return Object.values(this._locations || {}).filter((l) => l?.visited).length || 0;
    }
    public get locationsTotal(): number {
        return Object.values(this._locations || {}).filter((l) => l.visitable).length || 0;
    }


    public get itemsCollected(): number {
        return [...new Set(this._inventory?.values() || [])].filter((i) => i.taken).length || 0;
    }

    public get itemsTotal(): number {
        return Object.values(this._entities || {}).filter((l) => l.carryable).length || 0;
    }

    public get currentTime(): string {
        if (this._hallucinating) {
            const hour = Math.floor(Math.random() * 12) + 1;
            const minute = Math.floor(Math.random() * 60);
            const ampm = Math.floor(Math.random() * 2) === 1 ? "am" : "pm";
            return `${hour}:${minute}${ampm}`
        } else {
            return "12:01am";
        }
    }

    public get score(): number {
        return (this.fragmentsFound * 2000)
             + (this.itemsCollected * 100)
             + (this.locationsVisited * 50)
             + (this.sanity * 25);
    }

    public get maxScore(): number {
        return (6 * 2000)
             + (this.itemsTotal * 100)
             + (this.locationsTotal * 50)
             + (MAX_SANITY * 25);
    }

    public get duration() {
        return moment.utc(moment.duration(moment().diff(this._startedAt)).asMilliseconds()).format("HH:mm:ss");
    }

    public get fragmentsFound(): number {
        return this._fragmentsFound;
    }

    public set currentLocation(location: Location) {
        this._currentLocation = location;
        this._currentLocation.visited = true;
    }

    public get currentExits(): string[] {
        return Object.entries(this._currentLocation.connections).flatMap(([d, name]) => {
            return name in this._locations ? d : "";
        });
    }

    public get currentLocation(): Location {
        return this._currentLocation;
    }

    // Game logic

    accept(command: Command): string[] {
        const [action, ...args] = command;
        const prevSanity = this.sanityLevel;

        const tick: () => string[] = () => {
            // We short-circuit and block most commands when in the game over state
            if (this._gameOver) {
                switch (action) {
                    case GO:
                        return this.go(args[0] as Direction);

                    default:
                        return this._("cant_do_game_over");
                }
            }

            // We short-circuit and block all commands when in the game won state
            if (this._gameWon) {
                switch (action) {
                    default:
                        return this._("cant_do_game_won");
                }
            }

            switch (action) {
                case GO:
                    return this.go(args[0] as Direction);

                case LOOK:
                    const lookingAt = args[0];

                    // Looking at something?
                    if (lookingAt) {

                        // Looking at a specific inventory item?
                        if (this._inventory.has(lookingAt)) {
                            const item = this._inventory.get(lookingAt)!;
                            if (item.visible)
                                return [item.description];
                        }

                        // Looking at a specific location item?
                        if (this._currentLocation.has(lookingAt)) {
                            const locationItem = this._currentLocation.get(lookingAt)!;
                            if (locationItem.visible) {
                                return [locationItem.description];
                            }
                        }

                        // Looking at a specific location prop?
                        const lookingAtProp = "__" + lookingAt
                        if (this._currentLocation.has(lookingAtProp)) {
                            const locationProp = this._currentLocation.get(lookingAtProp)!;
                            if (locationProp.visible) {
                                return [locationProp.description];
                            }
                        }

                        // Looking in a specific direction?
                        const maybeDirection = lookingAt as Direction;
                        if (Directions.includes(maybeDirection)) {
                            return this.lookInDirection(maybeDirection);
                        }

                        // Not found
                        return this._("nothing_to_see");

                    // Looking at the current location?
                    } else {
                        return this.look();
                    }

                case USE:
                    if (args.length === 2) {
                        return this.use(args[0], args[1]);
                    } else {
                        return this.use(args[0]!);
                    }

                case TAKE:
                    return this.take(args[0]!);

                case OPEN:
                    const toOpen = args[0];
                    if (toOpen && this._inventory.has(toOpen)) {
                        return this._inventory.get(toOpen)!.open();
                    }

                    if (toOpen && this._currentLocation.has(toOpen)) {
                        return this._currentLocation.get(toOpen)!.open();
                    }

                    return this._("nothing_to_open");

                case CLOSE:
                    const toClose = args[0];
                    if (toClose && this._inventory.has(toClose)) {
                        return this._inventory.get(toClose)!.close();
                    }

                    if (toClose && this._currentLocation.has(toClose)) {
                        return this._currentLocation.get(toClose)!.close();
                    }

                    return this._("nothing_to_close");

                case INVENTORY:
                    return this.listInventory();

                case SANITY:
                    return this._("sanity");

                case PROGRESS:
                    return this._("progress");

                default:
                    return this._("unsure");
            }
        };

        const results = tick();

        // Check for gameover state
        if (this._sanity === 0 && !this._gameOver) {
            return [
                ...results,
                ...this.lose(),
            ];
        }

        // Check for win state
        if (this._fragmentsFound === 6 && !this._gameWon) {
            return [
                ...results,
                ...this.win(),
            ]
        }

        // Check for change in sanity state
        if (prevSanity !== this.sanityLevel) {
            return [
                ...results,
                ...this._("sanity_change"),
            ]
        }

        // No major state changes, just return the results
        return results;
    }

    listInventory(): string[] {
        if (this._inventory.size) {
            const items = new Set(Array.from(this._inventory.values())
                .filter((e) => e.listable && !e.hidden)
                .map((e) => e.nameWithDeterminer));

            return this._("carrying", {items: join("and", ...items)});
        } else {
            return this._("carrying_nothing");
        }
    }

    start(): string[] {
        return this._("introduction");
    }

    go(d: Direction): string[] {
        const next = this._currentLocation.connection(d)
        if (next && next in this._locations) {
            const prevLoc = this._currentLocation;
            const nextLoc = this._locations[next]!;

            const cantEnterReasons = nextLoc.canEnter(d, this._currentLocation);
            const cantLeaveReasons = prevLoc.canLeave(d, nextLoc);

            // Empty lists if there are no reasons the player can't travel between the locations
            if (cantEnterReasons.length > 0 || cantLeaveReasons.length > 0) {
                return [...cantEnterReasons, ...cantLeaveReasons];
            }

            if (!this._gameOver) {
                this._steps++;
                // Take more sanity damage when hallucinating
                this.damageSanity(this._hallucinating ? 2 : 1);

                // Trigger game over here to avoid showing the location description
                if (this._sanity <= 0) {
                    return [];
                }
            }

            nextLoc.enter();

            // Override the leave/exit message if needed
            const onLeave = prevLoc.onLeave(d, nextLoc);
            const onEnter = nextLoc.onEnter(d, prevLoc);
            const exits = this.currentExits;

            return [
                ...(onLeave.length > 0 ? onLeave : this._("travel", {direction: d})),
                ...(onEnter.length > 0 ? onEnter : this._("look", {
                    location_name: this._currentLocation?.name || "unknown",
                    location_description: this._currentLocation?.description || "unknown",
                })),
                ...((exits.length > 1 && this._("exits", { exits: join("and", ...exits) })) || (exits.length === 1 && this._("exit", {exit: exits[0]})) || this._("no_exits")),
            ];
        } else {
            return this._("cannot_travel");
        }
    }

    look(): string[] {
        const exits = this.currentExits;

        return [
            ...this._("look", {
                location_name: this._currentLocation?.name || "unknown",
                location_description: this._currentLocation?.description || "unknown",
            }),
            ...((exits.length > 1 && this._("exits", { exits: join("and", ...exits) })) || (exits.length === 1 && this._("exit", {exit: exits[0]})) || this._("no_exits")),
        ];
    }

    lookInDirection(d: Direction): string[] {
        const next = this._currentLocation.connection(d)

        if (next && this._locations[next]) {
            return this._("look_in_direction", {location_name: this._locations[next]!.name.toLowerCase(), direction: d})
        } else {
            return this._("nothing_in_direction")
        }
    }

    take(id: string): string[] {
        const item = this._currentLocation.get(id) || this._currentLocation.get(`__${id}`) ;
        return (item && item.lit && !item.hidden && item.take(true)) || this._("nothing_to_take", { item: id.toLowerCase() });
    }

    use(id1: string, id2?: string): string[] {
        // Attempted to use id1 on id2
        if (id1 && id2) {
            // Check inventory, location, and then location props
            const item1 = this._inventory.get(id1) || this._currentLocation.get(id1) || this._currentLocation.get(`__${id1}`);
            const item2 = this._inventory.get(id2) || this._currentLocation.get(id2) || this._currentLocation.get(`__${id2}`);

            if (item1 && item2) {
                return item1.useOn(item2);
            } else if (item1) {
                return this._("cant_use_entity_on", { item: item1.name });
            } else {
                return this._("no_entity_to_use", { item: id1.toLowerCase() });
            }

        // Attempted to use id1 by itself
        } else {
            const item = this._inventory.get(id1) || this._currentLocation.get(id1) || this._currentLocation.get(`__${id1}`);
            return item ? item.use() : this._("nothing_to_use");
        }
    }

    damageSanity(amount: number): void {
        this._sanity = Math.min(MAX_SANITY, Math.max(this._sanity - amount, 0));
    }

    recordProgress(): void {
        this._fragmentsFound++;
    }

    win(): string[] {
        this._gameWon = true;
        const ctx = this.context();
        this._sanity = MAX_SANITY;
        return this._("game_won", ctx);
    }

    lose(): string[] {
        this._gameOver = true;
        this._currentLocation = this._locations["ENDLESS_VOID"]!;
        const ctx = this.context();
        this._sanity = MAX_SANITY;
        return this._("game_over", ctx);
    }

    context(extra: Record<string, any> = {}): Record<string,Record<string, any>> {
        const inventoryItems = (this._inventory && Object.fromEntries(this._inventory)) || {}
        const locationItems = (this.currentLocation && Object.fromEntries(this.currentLocation.contents|| new Map())) || {}
        const ctx = {
            "player": {
                "current_time": this.currentTime,
                "game_duration": this.duration,
                "hallucinating": this._hallucinating,
                "score": this.score,
                "max_score": this.maxScore,
                "sanity": this._sanity,
                "sanity_level": this.sanityLevel,
                "steps": this._steps,
                "phone_calls": this.phoneCallsMade,
                "safe_attempts": this.safeCrackingAttempts,
                "items_collected": this.itemsCollected,
                "items_total": this.itemsTotal,
                "locations_visited": this.locationsVisited,
                "locations_total": this.locationsTotal,
                "fragments_found": this._fragmentsFound,
                "slipped_on_stairs": this.slippedOnStairs,
                "found_cellar_ladder": this.foundCellarLadder,
                "found_treehouse": this.foundTreehouse,
                "attacked_by_ghosts": this.attackedByGhosts,
                "attacked_by_bats": this.attackedByBats,
                "attacks_survived": this.attackCount,
                "fallen_from_spooky_room": this.fallenFromSpookyRoom,
            },
            "inventory": inventoryItems,
            "location": locationItems,
            ...extra,
        }

        return ctx;
    }

    protected _(key: StringsKey, extra?: Record<string, any>): string[] {
        const tpl: string = (strings && strings["GAME"][key]) || key;
        return [mustache.render(tpl, this.context(extra))];
    }
}