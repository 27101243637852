import { Entity } from "./Entity";
import { CauldronEntity } from "./CauldronEntity";

export const enum ContainerContents {
    EMPTY = "empty",
    WATER = "water",
    MARSH_WATER = "marsh_water",
    BUBBLING_MARSH_WATER = "bubbling_marsh_water",
}

export class ContainerEntity extends Entity {
    contents: ContainerContents = ContainerContents.EMPTY;

    useOn(other: Entity): string[] {
        const empty = this.contents === ContainerContents.EMPTY;

        if (other.id === "CAULDRON") {
            if (empty) {
                return this._("use_on_cauldron_empty", "CONTAINER_ENTITY");
            }

            const cauldron = other as CauldronEntity;
            if (cauldron.water) {
                return this._("use_on_cauldron_again", "CONTAINER_ENTITY");
            }

            if (this.contents === ContainerContents.BUBBLING_MARSH_WATER) {
                this.contents = ContainerContents.EMPTY;
                cauldron.water = true;
                return this._("use_on_cauldron", "CONTAINER_ENTITY");
            }

            // Used the wrong liquid, OOPS
            this._game.damageSanity(5);
            this.contents = ContainerContents.EMPTY;
            return this._("use_on_cauldron_not_like_this", "CONTAINER_ENTITY");
        }

        if (other.id === "WATER") {
            this.contents = ContainerContents.WATER;
            return empty ? this._("use_with_water", "CONTAINER_ENTITY") : this._("empty_and_use_with_water", "CONTAINER_ENTITY");
        }

        if (other.id === "MARSH") {
            this.contents = ContainerContents.MARSH_WATER;
            return empty ? this._("use_with_marsh", "CONTAINER_ENTITY") : this._("empty_and_use_with_marsh", "CONTAINER_ENTITY");
        }

        if (other.id === "BUBBLINGMARSH") {
            this.contents = ContainerContents.BUBBLING_MARSH_WATER;
            return empty ? this._("use_with_bubbling_marsh", "CONTAINER_ENTITY") : this._("empty_and_use_with_bubbling_marsh", "CONTAINER_ENTITY");
        }

        if (empty) {
            return this._("use_on_cauldron_empty", "CONTAINER_ENTITY");
        }

        this.contents = ContainerContents.EMPTY;
        return this._("use_on_anything_else", "CONTAINER_ENTITY");
    }
}
