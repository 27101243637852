import { Entity } from "./Entity";
import { U } from "../Direction";

export class FlashlightEntity extends Entity {
    use(): string[] {
        // Light up any unlit entities so that they can be seen
        this._game.currentLocation.contents.forEach((e) => {
            e.lit = true;
        });

        // Return a new description for the room
        switch (this._game.currentLocation.id) {
            case "CRAWLSPACE":
                return this._("crawlspace");

            case "DEEP_CELLAR":
                // Connect the deep cellar to the cellar
                this._game.foundCellarLadder = true;
                this._game.currentLocation.connect(U, "CELLAR");
                return this._("deep_cellar");

            case "GLOOMY_PASSAGE":
                return this._("gloomy_passageway");

            default:
                return this._("no_effect");
        }
    }
}
