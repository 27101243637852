import { Entity } from "./Entity";
import { DoorEntity } from "./DoorEntity";

export class KeyEntity extends Entity {
    useOn(other: Entity): string[] {
        if (other.id === "FRONTDOOR" || other.id === "KITCHENDOOR") {
            (other as DoorEntity).locked = false;
            return this._("unlock_door", other);
        } else {
            return super.useOn(other);
        }
    }
}
