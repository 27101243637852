import { Game } from "../Game";
import { Entity } from "./Entity";

import entityDefinitions from './Entities.json'
import { RopeEntity } from "./RopeEntity";
import { KitchenDoorEntity } from "./KitchenDoorEntity";
import { FrontDoorEntity } from "./FrontDoorEntity";
import { KeyEntity } from "./KeyEntity";
import { IPhoneEntity } from "./IPhoneEntity";
import { FlashlightEntity } from "./FlashlightEntity";
import { CameraEntity } from "./CameraEntity";
import { EmeraldEntity, SapphireEntity } from "./GemstoneEntity";
import { GemstoneBoxEntity } from "./GemstoneBoxEntity";
import { VacuumEntity } from "./VacuumEntity";
import { BatteryEntity } from "./BatteryEntity";
import { SpraycanEntity } from "./SpraycanEntity";
import { SandEntity } from "./SandEntity";
import { ShovelEntity } from "./ShovelEntity";
import { LighterEntity } from "./LighterEntity";
import { PaintingEntity } from "./PaintingEntity";
import { CauldronIngredientEntity } from "./CauldronIngredientEntity";
import { BugNetEntity } from "./BugNetEntity";
import { BustEntity } from "./BustEntity";
import { ContainerEntity } from "./ContainerEntity";
import { FlyEntity } from "./FlyEntity";
import { CauldronEntity } from "./CauldronEntity";
import { CoalEntity } from "./CoalEntity";
import { NapkinEntity } from "./NapkinEntity";
import { GarmentEntity } from "./GarmentEntity";
import { PotionEntity } from "./PotionEntity";
import { PhoneEntity } from "./PhoneEntity";
import { SafeEntity } from "./SafeEntity";
import { DeskEntity } from "./DeskEntity";
import { WireEntity } from "./WireEntity";
import { ChestEntity } from "./ChestEntity";
import { AxeEntity } from "./AxeEntity";
import { HookEntity } from "./HookEntity";
import { AmuletEntity } from "./AmuletEntity";
import { DiningTableEntity } from "./DiningTableEntity";

export type EntityDefinitions = typeof entityDefinitions;
export type NamedEntity = keyof EntityDefinitions;
export type Entities = { [key in NamedEntity]?: Entity };

export function initializeEntities(game: Game): Entities {
    const entities: Entities = {};
    const defs: EntityDefinitions = entityDefinitions as EntityDefinitions

    function initializeEntity<E extends Entity>(id: NamedEntity, type?: { new(name: string, game: Game, defs: EntityDefinitions): E;}): void {
        entities[id] = (type && new type(id, game, defs)) || new Entity(id, game, defs);
    }

    // Initial player inventory
    initializeEntity("CAMERA", CameraEntity);
    initializeEntity("IPHONE", IPhoneEntity);
    initializeEntity("FLASHLIGHT", FlashlightEntity);

    // Front door puzzle
    initializeEntity("BOX", GemstoneBoxEntity);
    initializeEntity("EMERALD", EmeraldEntity);
    initializeEntity("SAPPHIRE", SapphireEntity);
    initializeEntity("ROPE", RopeEntity);
    initializeEntity("FRONTDOOR", FrontDoorEntity);
    initializeEntity("KEY", KeyEntity);

    // Rear door puzzle -- also uses KEY
    initializeEntity("KITCHENDOOR", KitchenDoorEntity);

    // Tree house puzzle -- also uses ROPE
    initializeEntity("AXE", AxeEntity);
    initializeEntity("WALL");
    initializeEntity("HINTBOOK");
    initializeEntity("HOOK", HookEntity);

    // Earthen staircase puzzle
    initializeEntity("AMULET", AmuletEntity);

    // Phone number puzzle
    initializeEntity("DESK", DeskEntity);
    initializeEntity("PHONE", PhoneEntity);
    initializeEntity("PHONEBOOK");
    initializeEntity("CLOCK");
    initializeEntity("SAFE", SafeEntity);
    initializeEntity("WIRE", WireEntity);
    initializeEntity("CHEST", ChestEntity);
    initializeEntity("TABLET");

    // Bat puzzle
    initializeEntity("ATTACKBATS");
    initializeEntity("BATS");
    initializeEntity("SPRAYCAN", SpraycanEntity);

    // Ghost puzzle
    initializeEntity("GHOSTS");
    initializeEntity("BATTERY", BatteryEntity);
    initializeEntity("VACUUM", VacuumEntity);
    initializeEntity("DININGTABLE", DiningTableEntity);

    // Cellar puzzle
    initializeEntity("SHOVEL", ShovelEntity);
    initializeEntity("SAND", SandEntity);

    // Dining room
    initializeEntity("NAPKIN", NapkinEntity);
    initializeEntity("PLATE");
    initializeEntity("CUP", ContainerEntity);
    initializeEntity("SILVERWARE");

    // Dark alcove puzzle
    initializeEntity("BUST", BustEntity);

    // Potion puzzle
    initializeEntity("COATS", GarmentEntity);
    initializeEntity("EGG", CauldronIngredientEntity);
    initializeEntity("SKULL", CauldronIngredientEntity);
    initializeEntity("FLY", FlyEntity);
    initializeEntity("NET", BugNetEntity);
    initializeEntity("WATER");
    initializeEntity("MARSH");
    initializeEntity("BUBBLINGMARSH");
    initializeEntity("PITCHER", ContainerEntity);
    initializeEntity("COALS", CoalEntity);
    initializeEntity("POTION", PotionEntity);
    initializeEntity("CAULDRON", CauldronEntity);

    // Trapdoor puzzle
    initializeEntity("LIGHTER", LighterEntity);
    initializeEntity("PAINTING", PaintingEntity)

    for (const entityId of Object.keys(entityDefinitions)) {
        if (!(entityId in entities)) {
            initializeEntity(entityId as NamedEntity);
        }
    }

    return entities;
}
