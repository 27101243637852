import { Entity } from "./Entity";

// Front door puzzle
export abstract class GemstoneEntity extends Entity {
    baseIds: string[] = ["GEMSTONE", "ENTITY"];
    abstract pairedGem: string;

    useOn(other: Entity): string[] {
        if (this._game.currentLocation.id === "STONE_ARCH" && (other.id === "ARCH" || other.id === "NOTCH")) {
            this.carryable = false;
            this.store();

            // Check if the player solved the puzzle
            let solved: string[] = [];
            if (this._game.currentLocation.has(this.pairedGem)) {
                this._game.entities["KEY"]?.take();
                solved = this._("solved", other);
            }

            return [
                ...this._("use_on_arch", other),
                ...solved,
            ];
        } else {
            return super.useOn(other);
        }
    }
}

export class EmeraldEntity extends GemstoneEntity {
    pairedGem = "SAPPHIRE";
}

export class SapphireEntity extends GemstoneEntity {
    pairedGem = "EMERALD";
}