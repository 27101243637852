import { OpenableEntity } from "./OpenableEntity";

export class DeskEntity extends OpenableEntity {
    takenPhonebook: boolean = false;

    open(): string[] {
        if (!this.opened && !this.takenPhonebook) {
            this._game.entities.PHONEBOOK?.take();
            return [
                ...super.open(),
                ...this._("open_with_book"),
            ];
        }

        return super.open();
    }

}