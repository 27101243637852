import { U } from "../Direction";
import { Entity } from "./Entity";

export class BustEntity extends Entity {
    fallen: boolean = false;

    take(removeFromLocation?: boolean): string[] {
        if (this._game.currentLocation.id === "DARK_ALCOVE") {
            this._game.currentLocation.connect(U, "DUSTY_ROOM");
        }
        this.fallen = true
        return this._("cant_take");
    }
}