import { N } from "../Direction";
import { Entity } from "./Entity";

export class GarmentEntity extends Entity {
    set description(value: string) {
        super.description = value;
    }

    get description(): string {
        this._game.currentLocation.connect(N, "CRAWLSPACE");
        return super.description;
    }
}