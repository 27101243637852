import { Entity } from "./Entity";

export class SpraycanEntity extends Entity {
    used: boolean = false;

    useOn(other: Entity): string[] {
        // Kill the ghosts
        if (other.id === "ATTACKBATS") {
            this.used = true;
            this.carryable = false;
            this.removable = false;

            // Drop the used spraycan
            this.store();

            // Remove the attacking bats
            this._game.currentLocation.remove("ATTACKBATS");

            // Add the dead bat bodies (ew)
            this._game.currentLocation.store(this._game.entities["BATS"]!);

            return this._("use_on_bats");
        }
        return super.useOn(other);
    }
}
