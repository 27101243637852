import styled from '@emotion/styled/macro';
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import './App.css';
import { Game, MAX_SANITY } from './game/Game';
import { parseCommand, UNKNOWN, HELP, COMMANDS, INVALID, RESTART, RULES, START } from './game/Command';
import { Location } from './game/location/Location';

import remarkGfm from 'remark-gfm';

import strings from './game/Strings.json'
import mustache from 'mustache';
import { CameraEntity } from './game/entity/CameraEntity';

declare global {
  interface Window {
    game: Game;
  }
}

type StringsKey = keyof typeof strings["APP"];
const _ = (key: StringsKey, ctx: Record<string, any> = {}): string =>
  mustache.render(strings["APP"][key], ctx);

const SANITY_CAP = 400;
const insanifyReplacements = [".", "$", ":", "~", "?", "-", "+", "*"];
function insanify(sanity: number, text: string): string {
  // Skip if sanity is too high
  if (sanity >= SANITY_CAP) {
    return text;
  }

  // Skip strings marked as important
  if (text.startsWith("!!!")) {
    return text.slice(4);
  }

  // Go insane otherwise
  const sanityCapped = Math.min(SANITY_CAP, sanity);
  const ratio = (SANITY_CAP - sanityCapped)/(SANITY_CAP);
  const numModifications = Math.max(0, Math.floor((text.length * ratio)/20));

  for (let idx = 0; idx < numModifications; idx++) {
    let pos = Math.floor(Math.random() * text.length);

    if (text.slice(pos, pos + 1).match(/[a-zA-Z0-9]/)) {
      if (Math.floor(Math.random() * 100) < 60) {
        text = text.slice(0, pos) + insanifyReplacements[(Math.floor(Math.random()*insanifyReplacements.length))] + text.slice(pos + 1, text.length);
      } else {
        const repeats = Math.floor(Math.random() * 3) + 1;
        text = text.slice(0, pos)
          + text.slice(pos, pos+1).repeat(repeats)
          + text.slice(pos + 1, text.length);
      }
    }
  }

  return text;
}

const Debug = styled.div`
  position: absolute;
  width: 50%;
  left: 50%;
  background: rgba(1,1,1, 0.66);
  color: #ccc;
  font-size: 90%;

  table {
    padding: 5px;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;

    th, td {
      padding: 5px 10px;
      text-align: left;
      vertical-align: top;
      border-left: 1px solid #444;
      border-bottom: 1px solid #444;
    }
  }
`;

const Container = styled.div`
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 16px;
`

const Header = styled.h1`
  padding: 0 20px 10px;
  margin: 20px 0 0;
  border-bottom: 1px solid #eee;
  font-family: Helvetica, sans-serif;
  font-size: 28px;
  display: block;

  small {
    padding-left: 5px;
    font-size: 50%;
    font-weight: normal;
  }
`

const History = styled.dl`
  flex-grow: 1;
  background: white;
  margin: 0;
  padding: 20px;
  overflow: hidden;
  overflow-y: scroll;

  p, ol, ul, li {
    margin: 10px 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  hr {
    border: solid #ddd;
    border-width: 1px 0 0 0;
  }
`

const CommandPrompt = styled.input`
  outline: none;
  border: none;
  background: #eee;
  margin-top: 0;
  padding: 20px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 16px;
  text-transform: uppercase;
`

const Command = styled.dt`
  &:before {
    content: "»";
    margin-right: 5px;
    margin-top: 10px;
  }
  margin-top: 20px;
  text-transform: uppercase;
  color: #666;
  display: flex;
  align-items: center;
`
const Result = styled.dd`
  margin: 0;
  padding: 0 0 20px;

  ul, ol {
    margin-bottom: 20px;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;

    td, th {
      padding: 5px 10px;
      border: 1px solid #ddd;
    }

    th {
      text-transform: uppercase;
      font-color: #666;
      text-align: left;
      background: #ddd;
    }
  }
`
const Information = styled.dd`
  margin: 15px 0 0;
  padding: 0;
`

type InfoEvent = { kind: "info", details: string[], sanity: number }
type ActionEvent = { kind: "action", command: string, results: string[], sanity: number }
type Event = InfoEvent | ActionEvent;

let commandHistory: string[] = [];
let commandHistoryIdx: number = 0;

const App = () => {
  const [game, setGame] = useState<Game>(new Game());
  const [initialized, setInitialized] = useState(false);
  const [started, setStarted] = useState(false);
  const [history, setHistory] = useState<Event[]>([]);
  const [command, setCommand] = useState("");

  function usingiOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(window.navigator.platform)
    // iPad on iOS 13 detection
    || (window.navigator.userAgent.includes("Mac") && "ontouchend" in window.document)
  }

  // Ensure history scrolls to bottom when updated
  useLayoutEffect(() => {
    const historyEl = historyRef.current;
    const promptEl = promptRef.current;
    if (historyEl) {
      if (promptEl && usingiOS()) {
        // Lose focus on the input when using iOS to hide the keyboard
        promptEl.blur();
      }

      historyEl.querySelectorAll('dd:last-child')[0].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center'
      });
    }
  }, [history]);

  const headerRef = useRef<HTMLHeadingElement>(null);
  const historyRef = useRef<HTMLDListElement>(null);
  const promptRef = useRef<HTMLInputElement>(null);

  window.game = game;

  function actionEvent(action: string, ...results: string[]): ActionEvent {
    return { kind: "action", command: action.trim(), results: results, sanity: game.sanity };
  }

  function infoEvent(...details: string[]): InfoEvent {
    return { kind: "info", details: details, sanity: game.sanity };
  }

  const initializeGame = () => {
    const introduction = infoEvent(_("introduction"));

    setGame(new Game());
    setHistory([introduction,]);
    setStarted(false);
    setCommand("");
    setInitialized(true)
  }

  if (!initialized) {
    initializeGame();
  }

  const ENTER = "Enter"
  const NUMPAD_ENTER = "NumpadEnter"
  const ESCAPE = "Escape"
  const ARROWUP = "ArrowUp"
  const ARROWDOWN = "ArrowDown"
  const MAX_HISTORY: number = 30;

  const resetPromptCursor = () => {
    const promptEl = promptRef.current;
    if (promptEl) {
      if (command) {
        promptEl.setSelectionRange(command.length, command.length);
        promptEl.focus();
      }
    }
  }

  const onKeyUp = (event: React.KeyboardEvent) => {
    // Replace command with historical one if UP was pressed
    if (event.code === ARROWUP) {
      if (commandHistory.length > 0) {
        setCommand(commandHistory[commandHistoryIdx]);
        commandHistoryIdx = Math.min(commandHistoryIdx + 1, commandHistory.length - 1);
        resetPromptCursor();
      }
      return;
    }

    if (event.code === ARROWDOWN) {
      commandHistoryIdx = Math.max(0, commandHistoryIdx - 1);
      setCommand(commandHistory[commandHistoryIdx] || "");
      resetPromptCursor();
      return;
    }

    // Enter new command if ENTER was pressed
    if (event.code === ENTER || event.code === NUMPAD_ENTER) {
      // Return early if command is blank.
      if (!command || !command.trim()) {
        return;
      }

      // Update the history
      commandHistory = [command, ...commandHistory];
      commandHistory = commandHistory.slice(0, MAX_HISTORY);
      commandHistoryIdx = 0;

      let result: ActionEvent | InfoEvent;
      const parsedCommand = parseCommand(command);
      const [action, ...args] = parsedCommand;
      switch (action) {
        case UNKNOWN:
          result = actionEvent(command, _("dont_know_how", { arg: args[1] }));
          break;

        case INVALID:
          result = actionEvent(command, _("cant_do_that", { arg: args[1] }));
          break;

        // Game management commands
        case RULES:
          result = actionEvent(command, _("rules"));
          break;

        case HELP:
          const commands = Object.values(COMMANDS)
            .map(({usage, help}) => `| **${usage}** | ${help} |`)
            .join("\n");

          result = actionEvent(command, _("help", {commands: commands}))
          break;

        case START:
            if (!started) {
              setStarted(true);
              setHistory([infoEvent(...game.start()), actionEvent("LOOK", ...game.look())]);
              setCommand("");
              return;
            } else {
              result = actionEvent(command, );
            }
            break;

        case RESTART:
          if (started) {
            if (window.confirm(_("confirm_restart"))) {
              const newGame = new Game();
              setGame(newGame);
              setHistory([infoEvent(...newGame.start()), actionEvent("LOOK", ...newGame.look())]);
              setCommand("");
              return;
            }
            result = actionEvent(command, _("cancel_restart"));
          } else {
            result = infoEvent(_("use_start"));
          }
          break;

        // All other commands are handled
        default:
          if (started) {
            const insanified = game.accept(parsedCommand)
              .map(text => insanify(game.sanity, text));

            result = actionEvent(command, ...insanified);
          } else {
            result = infoEvent(_("use_start"));
          }
      }

      const updatedHistory = [...history, result];
      setHistory(updatedHistory.slice(Math.max(updatedHistory.length - MAX_HISTORY, 0)));
      setCommand("");


    // Clear command
  } else if (event.code === ESCAPE) {
      setCommand("");
    }
  }

  const loc = Object.values(game.locations) as unknown as Location[];
  const locTotal= loc.length;
  const locVisited = loc.filter((l) => l.visited).length;
  const foundFragments = (game.entities.CAMERA as CameraEntity | undefined)!.fragmentsFound;
  const cts = [...new Set(game.currentLocation.contents.values())]
    .map((entity) => {
      if (entity.aliases.length > 0)
        return `${entity.id} [${entity.aliases.join(", ")}]`
      else
        return entity.id
    })
    .join(", ");

  const inv = [...new Set(game.inventory.values())]
    .map((entity) => {
      if (entity.aliases.length > 0)
        return `${entity.id} [${entity.aliases.join(", ")}]`
      else
        return entity.id
    })
    .join(", ");

  const onClickFocus = (e: React.MouseEvent) => {
      if (e.target === historyRef.current || e.target === headerRef.current) {
        promptRef?.current?.focus();
      }
  }

  return <Container onClick={(e) => onClickFocus(e)}>
    {game.debug && <Debug>
        <table>
          <tbody>
          <tr><th>Loc</th><td>{game.currentLocation.name}</td></tr>
          <tr><th>Loc Contents</th><td>{cts}</td></tr>
          <tr><th>Loc Exits</th><td>{Object.entries(game.currentLocation.connections || {}).map(([k, v]) => `${k[0]}: ${v}`).join(", ")}</td></tr>
          <tr><th>Inventory</th><td>{inv}</td></tr>
          <tr><th>Steps Taken</th><td>{game.steps}</td></tr>
          <tr><th>Sanity</th><td>You feel {game.sanityLevel} ({game.sanity}/{MAX_SANITY})</td></tr>
          <tr><th>Score</th><td>{game.score}</td></tr>
          <tr><th>Fragments Found</th><td>{Array.from(Object.entries(foundFragments) || [], ([k, v]) => v ? k : "").join(", ")} ({game.fragmentsFound}/6)</td></tr>
          <tr><th>Loc Stats</th><td>Visited: {locVisited}/{locTotal}</td></tr>
          </tbody>
      </table>
    </Debug>}
    <Header ref={headerRef}>A Haunted Map Quest <small>BETA</small></Header>
    <History ref={historyRef}>
        {history.map((evt, idx) => {
          const {kind} = evt;
          switch (kind) {
            case "info":
              const {details} = evt;
              return <Information key={idx}>
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {details.join("\n\n")}
                  </ReactMarkdown>
                </Information>;

            case "action":
              const {command, results} = evt;
              return <Fragment key={idx}>
                <Command>
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {command}
                  </ReactMarkdown>
                </Command>
                <Result key={idx}>
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {results.join("\n\n")}
                  </ReactMarkdown>
                </Result>
              </Fragment>;

            default:
              return <></>;

          }})}
    </History>
    <CommandPrompt ref={promptRef} autoFocus placeholder={_("command_prompt")} value={command} onChange={(e) => setCommand(e.target.value)} onKeyUp={(e) => onKeyUp(e)}/>
  </Container>
}

export default App;
