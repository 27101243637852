import { SafeCodeEntity } from "../entity/SafeCodeEntity";
import { Entity } from "../entity/Entity";
import { Location } from "./Location";
import entityDefinitions from '../entity/Entities.json';

export class SafeLocation extends Location {
    has(id: string): boolean {
        if (id.match("[0-9][0-9][0-9][0-9]"))
            return true;
        return super.has(id);
    }

    get(id: string): Entity | undefined {
        if (id.match("[0-9][0-9][0-9][0-9]")) {
            const defs: EntityDefinitions = entityDefinitions as EntityDefinitions;
            const safeCode = new SafeCodeEntity("SAFECODE", this._game, defs);
            safeCode.code = id;
            return safeCode;
        }
        return super.get(id);
    }
}
