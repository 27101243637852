import { OpenableEntity } from "./OpenableEntity";

export class GemstoneBoxEntity extends OpenableEntity {
    hasGem: boolean = true;

    open(): string[] {
        if (this.opened) {
            return super.open();
        } else {
            if (this.hasGem) {
                this._game.entities["SAPPHIRE"]?.take();
                this.hasGem = true;
                this.discard();

                return [
                    ...super.open(),
                    ...this._("open_with_gem"),
                ];
            } else {
                return super.open();
            }
        }
    }
}
