import { OpenableEntity } from "./OpenableEntity";

export class SafeEntity extends OpenableEntity {
    locked: boolean = true;
    hasWire: boolean = true;
    numberAttempts: number = 0;

    open(): string[] {
        if (this.locked) {
            return this._("locked");
        } else {
            if (this.opened) {
                return super.open();
            } else {
                if (this.hasWire) {
                    this._game.entities.WIRE?.take();
                    this.hasWire = false;

                    return [
                        ...super.open(),
                        ...this._("open_with_wire")
                    ];
                } else {
                    return super.open();
                }
            }
        }
    }
}
