import { Entity } from "./Entity";

// Entity implementations
// ======================
// Initial player inventory
export class IPhoneEntity extends Entity {
    use(): string[] {
        return this._("use");
    }
}
