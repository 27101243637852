import { Entity } from "../entity/Entity";
import { Location } from "./Location";
import entityDefinitions from '../entity/Entities.json';
import { PhoneNumberEntity } from "../entity/PhoneNumberEntity";

export class PhoneLocation extends Location {
    has(id: string): boolean {
        if (id.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/))
            return true;
        return super.has(id);
    }

    get(id: string): Entity | undefined {
        if (id.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/)) {
            const defs: EntityDefinitions = entityDefinitions as EntityDefinitions;
            const phoneNumber = new PhoneNumberEntity("PHONENUMBER", this._game, defs);
            phoneNumber.phoneNumber = id;
            return phoneNumber;
        }
        return super.get(id);
    }
}
