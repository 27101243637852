import { Direction } from "../Direction";
import { Location } from "./Location";

export class CellarLocation extends Location {
    canLeave(d: Direction, to: Location): string[] {
        if (to.id === "TROPHY_ROOM" && !this.has("SAND")) {
            this._game.slippedOnStairs = true;
            this._game.damageSanity(10);
            return this._("fall", { direction: d });
        }
        return [];
    }
}
