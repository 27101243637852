import { Entity } from "./Entity";

export class CauldronEntity extends Entity {
    water: boolean = false;
    heated: boolean = false;
    solved: boolean = false;

    use(): string[] {
        const skull = this._game.currentLocation.has("SKULL");
        const fly = this._game.currentLocation.has("FLY");
        const egg = this._game.currentLocation.has("EGG");
        const solved = skull && fly && egg && this.water;

        if (solved && !this.solved) {
            if (this.heated) {
                this._game.entities["POTION"]!.take();
                this._game.currentLocation.remove("SKULL");
                this._game.currentLocation.remove("FLY");
                this._game.currentLocation.remove("EGG");
                this.water = false;
                this.solved = true;
                return this._("take_potion");
            } else {
                return this._("needs_heat");
            }
        }

        if (this.solved) {
            return this._("already_used")
        }

        return this._("cant_use");
    }
}
