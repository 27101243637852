import { Direction, N, S } from "../Direction";
import { LocationName } from "../location/Locations";
import { DoorEntity } from "./DoorEntity";


export class FrontDoorEntity extends DoorEntity {
    direction1: Direction = S;
    location1: LocationName = "FRONT_PORCH";

    direction2: Direction = N;
    location2: LocationName = "FRONT_LOBBY";
}
