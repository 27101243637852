import { N } from "../Direction";
import { Entity } from "./Entity"

export class AxeEntity extends Entity {
    broken: boolean = false;

    useOn(other: Entity): string[] {
        if (this._game.currentLocation.id === "STUDY" && other.id === "WALL") {
            // Leave the axe behind
            this.broken = true;
            this.carryable = false;
            this.store();

            // Add the connection to the secret room and remove the wall
            this._game.currentLocation.connect(N, "SECRET_ROOM");
            other.discard();
            return this._("use_on_wall", other);
        }

        return super.useOn(other);
    }
}