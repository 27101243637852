import { N, S, E, W, U, D } from "./Direction";

export const GO = "GO" as const;
export const LOOK = "LOOK" as const;
export const TAKE = "TAKE" as const;
export const OPEN = "OPEN" as const;
export const CLOSE = "CLOSE" as const;
export const USE = "USE" as const;
export const INVENTORY = "INVENTORY" as const;
export const RULES = "RULES" as const;
export const SANITY = "SANITY" as const;
export const PROGRESS = "PROGRESS" as const;
export const START = "START" as const;
export const RESTART = "RESTART" as const;
export const HELP = "HELP" as const;
export const UNKNOWN = "UNKNOWN" as const;
export const INVALID = "INVALID" as const;

const DIRECTIONS = [N, S, E, W, U, D] as const;
type Direction = typeof DIRECTIONS[number];

const VERBS = [GO, LOOK, TAKE, OPEN, CLOSE, USE, INVENTORY, RULES, START, RESTART, SANITY, PROGRESS, HELP] as const;
type Verb = typeof VERBS[number]

type GoCommand = ["GO", string?];
type LookCommand = ["LOOK", string?];
type TakeCommand = ["TAKE", string];
type OpenCommand = ["OPEN", string];
type CloseCommand = ["CLOSE", string];
type UseCommand = ["USE", string, string?];
type InventoryCommand = ["INVENTORY"];
type RulesCommand = ["RULES"];
type StartCommand = ["START"];
type RestartCommand = ["RESTART"];
type ProgressCommand = ["PROGRESS"];
type SanityCommand = ["SANITY"];
type HelpCommand = ["HELP"];
type InvalidCommand = ["INVALID", string, string];
type UnknownCommand = ["UNKNOWN", string, string];
type EmptyCommand = [];

export type Command =
    | GoCommand
    | LookCommand
    | TakeCommand
    | OpenCommand
    | CloseCommand
    | UseCommand
    | InventoryCommand
    | RulesCommand
    | StartCommand
    | RestartCommand
    | SanityCommand
    | ProgressCommand
    | HelpCommand
    | InvalidCommand
    | UnknownCommand
    | EmptyCommand
    ;

type CommandDefinition = {
    min: number
    max: number
    usage: string
    help: string
    hidden: boolean
}

type CommandDefinitionList = {
    [v in Verb]: CommandDefinition
}

const HELP_MESSAGE = "Use HELP to display useful commands";

export const COMMANDS: CommandDefinitionList = {
    GO:         { min: 1, max: 1,
                  usage: "GO <DIRECTION>",
                  help: "Use GO to travel NORTH, SOUTH, WEST, EAST, UP or DOWN. You can also use the first letter of each direction to travel quickly in that direction" ,
                  hidden: false },

    LOOK:       { min: 0, max: 1,
                  usage: "LOOK [AT <OBJECT>]",
                  help: "Use LOOK to at your current location or a specific OBJECT in the location or your inventory" ,
                  hidden: false },

    TAKE:       { min: 1, max: 1,
                  usage: "TAKE <OBJECT>",
                  help: "Use TAKE to take an OBJECT from the current location and put it in your inventory",
                  hidden: false },

    OPEN:       { min: 1, max: 1,
                  usage: "OPEN <OBJECT>",
                  help: "Use OPEN to open OBJECT in the current location or your inventory",
                  hidden: false },

    CLOSE:      { min: 1, max: 1,
                  usage: "CLOSE <OBJECT>",
                  help: "Use CLOSE to close OBJECT in the current location or your inventory",
                  hidden: false },

    USE:        { min: 1, max: 2,
                  usage: "USE <OBJECT> [ON <OTHER_OBJECT>]",
                  help: "Use USE with an OBJECT to use it in the current location or on OTHER_OBJECT",
                  hidden: false },

    INVENTORY:  { min: 0, max: 0,
                  usage: "INVENTORY",
                  help: "Use INVENTORY to list what you're currently carrying",
                  hidden: false },

    RULES:      { min: 0, max: 0,
                  usage: "RULES",
                  help: "Use RULES to read the rules for this game",
                  hidden: false },

    SANITY:     { min: 0, max: 0,
                  usage: "SANITY",
                  help: "Use SANITY to check your current sanity level",
                  hidden: false },

    PROGRESS:   { min: 0, max: 0,
                  usage: "PROGRESS",
                  help: "Use PROGRESS to show your current progress",
                  hidden: false },

    START:      { min: 0, max: 0,
                  usage: "START",
                  help: "Use START to abandon your current progress and restart the game",
                  hidden: true },

    RESTART:    { min: 0, max: 0,
                  usage: "RESTART",
                  help: "Use RESTART to abandon your current progress and restart the game",
                  hidden: false },

    HELP:       { min: 0, max: 0,
                  usage: "HELP",
                  help: HELP_MESSAGE,
                  hidden: false },
} as const;

const COMMAND_ALIASES: Record<string, string> = {
    "N": "GO NORTH",
    "NORTH": "GO NORTH",
    "S": "GO SOUTH",
    "SOUTH": "GO SOUTH",
    "E": "GO EAST",
    "EAST": "GO EAST",
    "W": "GO WEST",
    "WEST": "GO WEST",
    "U": "GO UP",
    "UP": "GO UP",
    "D": "GO DOWN",
    "DOWN": "GO DOWN",
    "L": "LOOK",
    "T": "TAKE",
    "O": "OPEN",
    "C": "CLOSE",
    "I": "INVENTORY",
    "P": "PROGRESS",
} as const;

const SKIP_WORDS = ["AT", "ON", "THE", "A"]

export function parseCommand(command: string): Command {
    const tokens = command
        .toUpperCase()
        .trim()
        .replaceAll(/[^\s\w-]+/g, '')
        .split(/\s+/g)
        .filter((v) => !SKIP_WORDS.includes(v));

    // No command
    if (tokens.length === 0) {
        return [];
    }

    // Handle any aliases
    const [first, ...rest] = tokens;
    if (first in COMMAND_ALIASES) {
        return parseCommand(`${COMMAND_ALIASES[first]} ${rest.join(" ")}`);
    }

    // Unknown command
    const verb = first as Verb;
    if (!VERBS.includes(verb)) {
        return ["UNKNOWN", verb, HELP_MESSAGE];
    }

    // Check for command arity and return if valid
    if (rest.length >= COMMANDS[verb].min && rest.length <= COMMANDS[verb].max) {
        // Validate DIRECTION in GO commands
        if (verb === "GO") {
            return DIRECTIONS.includes(rest[0] as Direction)
                ? tokens as Command
                : ["INVALID", verb, COMMANDS[verb].help];
        } else {
            return tokens as Command;
        }
    } else {
        return ["INVALID", verb, COMMANDS[verb].help];
    }

}