import { D } from "../Direction";
import { Entity } from "./Entity";

export class PotionEntity extends Entity {
    use(): string[] {
        // Start hallucinating
        this._game.hallucinating = true;
        this._game.damageSanity(100);

        // Add the earthen staircase to the cliff
        this._game.locations["CLIFF_PATH_2"]?.connect(D, "EARTHEN_STAIRCASE");

        // Discard the potion
        this.discard();

        return this._("use");
    }
}