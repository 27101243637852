import { Entity } from "./Entity";

export class DiningTableEntity extends Entity {
    public get empty(): boolean {
        const hasItems = this._game.currentLocation.has("PLATE")
            || this._game.currentLocation.has("NAPKIN")
            || this._game.currentLocation.has("SILVERWARE")
            || this._game.currentLocation.has("BATTERY")
            || this._game.currentLocation.has("CUP");

        return !hasItems;
    }
}