import { Direction } from "../Direction";
import { LocationName } from "../location/Locations";
import { OpenableEntity } from "./OpenableEntity";

export abstract class DoorEntity extends OpenableEntity {
    abstract direction1: Direction;
    abstract location1: LocationName;
    abstract direction2: Direction;
    abstract location2: LocationName;
    locked: boolean = true;

    open(): string[] {
        if (this.locked) {
            return this._("locked");
        } else {
            this._game.locations[this.location1]?.connect(this.direction2, this.location2);
            this._game.locations[this.location2]?.connect(this.direction1, this.location1);
            return [
                ...super.open(),
                ...this._("open_door"),
            ];
        }
    }

    close(): string[] {
        this._game.locations[this.location1]?.disconnect(this.direction2);
        this._game.locations[this.location2]?.disconnect(this.direction1);
        return super.close();
    }
}
