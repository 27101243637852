import { Entity } from "./Entity";

export class BugNetEntity extends Entity {
    useOn(other: Entity): string[] {
        if (other.id === "FLY") {
            // Take a fly and add it to our inventory
            for (const id of [other.id, ...other.aliases]) {
                this._game.inventory.set(id, other);
            }

            return this._("use_on");
        }

        return super.useOn(other);
    }
}