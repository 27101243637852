import { Game } from "../Game";
import mustache from 'mustache';

export class Entity {
    // Id of the entity
    protected _id: string;

    // Name of the entity
    protected _name: string;

    // Definitions used primarily for setting ENTITYs and string look-up
    protected _definitions: EntityDefinitions = {};

    // A description for the entity
    protected _description: string = "";

    // Alternate identities or aliases for this entity
    aliases: string[] = []

    // Used when referencing the entity in strings
    article: string = "a"

    // When true, entity can be used
    usable: boolean = false;

    // When true, entity can be used on another entity
    usableOn = false;

    // When true, entity can be listed in a player inventory if carried
    // When false, entity will be a hidden item in a player inventory if carried
    listable: boolean = true;

    // When true, entity can be carried in a player inventory
    carryable: boolean = false;

    // When true, entity can be stored in locations
    storable: boolean = false;

    // When true, entity can be removed from locations
    removable: boolean = false;

    // When true, entity can only be seen
    // When false, entity can only be revealed with the flashlight
    lit: boolean = true;

    // When true, entity can only be seen/interacted with when hallucinating
    imaginary: boolean = false;

    // When true, entity cannot be seen in a location or in the player's inventory
    hidden: boolean = false;

    // When true, entity was taken at least once during the game
    taken: boolean = false;

    // Enable additional lookups by id for strings if needed
    baseIds: string[] = ["ENTITY"];

    // Reference to the game object
    protected _game: Game;

    constructor(id: string, game: Game, defs?: Record<string, any>) {
        this._id = id;
        this._name = id.toLowerCase();
        this._game = game;
        this._definitions = defs || {};

        if (defs) {
            this.name = defs[id]?.name ?? this.name;
            this.description = defs[id]?.description ?? this.description;
            this.aliases = defs[id]?.aliases ?? this.aliases;
            this.article = defs[id]?.article ?? this.article;
            this.usable = defs[id]?.usable ?? this.usable;
            this.usableOn = defs[id]?.usable_on ?? this.usableOn;
            this.listable = defs[id]?.listable ?? this.listable;
            this.carryable = defs[id]?.carryable ?? this.carryable;
            this.storable = defs[id]?.storable ?? this.storable;
            this.removable = defs[id]?.removable ?? this.removable;
            this.lit = defs[id]?.lit ?? this.lit;
            this.imaginary = defs[id]?.imaginary ?? this.imaginary;
            this.hidden = defs[id]?.hidden ?? this.hidden;
        }
    }

    public get id(): string {
        return this._id;
    }

    public get name(): string {
        return mustache.render(this._name, this.context());
    }

    public set name(value: string) {
        this._name = value;
    }

    public get description(): string {
        return mustache.render(this._description, this.context());
    }

    public set description(value: string) {
        this._description = value;
    }

    public get definitions(): EntityDefinitions {
        return this._definitions;
    }

    get nameWithDeterminer(): string {
        return `${this.article} ${this.name}`;
    }

    // Check for item visibilty--ensure item is lit and not hidden, and if imaginary, ensure player is hallucinating
    get visible(): boolean {
        const visible = (this.lit && !this.hidden);
        return this.imaginary ? visible && this._game.hallucinating : visible;
    }

    take(removeFromLocation: boolean = false): string[] {
        if (this.carryable) {
            this.taken = true;

            for (const id of [this.id, ...this.aliases]) {
                this._game.inventory.set(id, this);
            }

            if (removeFromLocation) {
                if (this.removable) {
                    this._game.currentLocation.remove(this._id);
                } else {
                    return this._("cant_take");
                }
            }
            return this._("take");
        } else {
            return this._("cant_take");
        }
    }

    discard(): void {
        for (const id of [this.id, ...this.aliases]) {
            this._game.inventory.delete(id);
        }
    }

    store(): string[] {
        if (this.storable) {
            let dropped = this._game.inventory.delete(this._id);
            if (dropped) {
                for (const alias of this.aliases) {
                    this._game.inventory.delete(alias);
                }
                this._game.currentLocation.store(this);
                return this._("leave");
            } else {
                return this._("cant_leave");
            }
        }
        return [];
    }

    use(): string[] {
        if (this.usable) {
            return this._("use_nothing_happened");
        } else {
            if (this.usableOn) {
                return this._("use_on_what");
            } else {
                return this._("unable_to_use");
            }
        }
    }

    useOn(other: Entity): string[] {
        if (this.usableOn) {
            return this._("use_on_nothing_happened", other);
        } else {
            return this._("unable_to_use_on", other);
        }
    }

    open(): string[] {
        return this._("cant_open");
    }

    close(): string[] {
        return this._("cant_close");
    }

    // Event handlers

    onCarryEvent(): string[] {
        return [];
    }

    onDropEvent(): string[] {
        return [];
    }

    context(other?: Entity) {
        return {
            entity: this,
            other: other || {},
            ...this._game.context()
        };
    }

    protected _(key: string, otherOrOverrideId?: Entity | string, overrideId?: string): string[] {
        const other = (otherOrOverrideId
            && typeof otherOrOverrideId !== "string"
            && otherOrOverrideId)
            || undefined;

        const resolvedId = (otherOrOverrideId
            && typeof otherOrOverrideId === "string"
            && otherOrOverrideId)
            || overrideId
            || this._id;

        let strings: Record<string, string>  = {}
        let stringExists: boolean = false;

        const allIds = [resolvedId, ...this.baseIds];

        for (const id of allIds) {
            if (stringExists) break;
            strings = this._definitions[id]?.strings || {};
            stringExists = key in strings;
        }

        if (!stringExists) {
            const lookups = allIds.map((id) => `${id}.strings.${key}`).join(", ");
            throw new Error(`Missing string. Tried looking up: ${lookups}`)
        }

        const tpl: string = (strings && strings[key]) || key;
        return [mustache.render(tpl, this.context(other))];
    }
}
