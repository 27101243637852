import { Entity } from "./Entity";
import { U } from "../Direction";

export class RopeEntity extends Entity {
    hasHook: boolean = false;

    take(removeFromLocation: boolean = false): string[] {
        // If taking from the bench, get a free box
        if (this._game.currentLocation.id === "CRUMBLING_CLIFFTOP") {
            super.take(removeFromLocation);
            this._game.entities["BOX"]?.take();
            return this._("on_carry_cliffs_event");
        }

        // If taking from the tree, disable the ability to climb it
        if (this._game.currentLocation.id === "SPLIT_TREE") {
            super.take(removeFromLocation);
            this._game.currentLocation.disconnect(U);
            return this._("on_carry_tree_event");
        }

        return super.take(removeFromLocation);
    }

    useOn(other: Entity): string[] {
        // Room actions
        if (this._game.currentLocation.id === "SPLIT_TREE" && other.id === "TREE" && this.hasHook) {
            this._game.currentLocation.connect(U, "TREEHOUSE");
            this.store();
            return this._("use_on_treehouse", other);
        }

        // Use on hook
        if (other.id === "HOOK") {
            this.hasHook = true;
            other.discard();
            return this._("use_on_rope", other, "HOOK");
        }

        return super.useOn(other);
    }
}
