import { LocationName } from "../location/Locations";
import { Entity } from "./Entity";

// Phone number puzzle
export class PhoneEntity extends Entity {
    locations: LocationName[] = ["FOREST", "THICK_FOREST", "CRUMBLING_WALL", "OVERGROWN_GARDEN", "CLIFFTOP"];
    callablePhoneNumbers: string[] = ["212-101-1012", "120-112-1201", "112-011-2011"]
    correctPhoneNumber: string = this.callablePhoneNumbers[Math.floor(Math.random() * this.callablePhoneNumbers.length)];

    wired: boolean = false;
    solved: boolean = false;
    numberCalls: number = 0;

    dial(phoneNumber: string): string[] {
        this.numberCalls++;

        if (!this.wired)
            return this._("dial_no_dialtone");

        if (phoneNumber === this.correctPhoneNumber) {
            this.solve();
            return this._("dial_buried_in_backyard");
        }

        if (phoneNumber === "314-578-8263")
            return this._("dial_my_number");

        // Wrong number, OOP.
        if (this.callablePhoneNumbers.includes(phoneNumber)) {
            this._game.damageSanity(30);
            return this._("dial_demonic_voices");
        }

        if (Math.random() <= 0.2) {
            return this._("dial_busy_signal");
        } else {
            return this._("dial_no_answer");
        }
    }

    solve(): void {
        if (this.solved)
            return;

        // Pick a random location behind the house to bury the chest
        const chestLoc = this._game.locations[this.locations[Math.floor(Math.random()*this.locations.length)]]!;
        chestLoc.store(this._game.entities.GROUND!);

        this.solved = true;
    }
}
