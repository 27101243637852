import { Entity } from "./Entity";
import { SafeEntity } from "./SafeEntity";


export class SafeCodeEntity extends Entity {
    code: string = "0000";

    useOn(other: Entity): string[] {
        if (other.id === "SAFE") {
            const safeEntity = other as SafeEntity;
            safeEntity.numberAttempts++;

            if (!safeEntity.locked)
                return this._("already_cracked");

            if (this.code === "1202") {
                safeEntity.locked = false;
                return this._("cracked_the_safe_code");
            } else {
                return this._("bad_code_to_the_safe");
            }
        } else {
            return super.useOn(other);
        }
    }
}
