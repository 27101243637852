import { Entity } from "./Entity";


export class CauldronIngredientEntity extends Entity {
    useOn(other: Entity): string[] {
        if (other.id === "CAULDRON") {
            this.store();
            return this._("use_on_cauldron", "CAULDRON");
        }

        return super.useOn(other);
    }
}
