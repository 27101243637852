import { Entities } from "../entity/Entities";
import { Location } from "./Location";
import { Game } from "../Game";

import locationDefinitions from './Locations.json';
import { CellarLocation } from "./CellarLocation";
import { SpookyRoomLocation } from "./SpookyRoomLocation";
import { TrophyRoomLocation } from "./TrophyRoomLocation";
import { UpperGalleryLocation } from "./UpperGalleryLocation";
import { SafeLocation } from "./SafeLocation";
import { DustyRoomLocation } from "./DustyRoomLocation";
import { DeepCellarLocation } from "./DeepCellarLocation";
import { PhoneLocation } from "./PhoneLocation";

export type LocationDefinitions = typeof locationDefinitions;
export type LocationName = keyof LocationDefinitions;
export type Locations = {
    [key in LocationName]?: Location
};

export function initializeLocations(game: Game, entities: Entities): Locations {
    const locations: Locations = {};

    function initializeLocation<L extends Location>(name: LocationName, type: { new(name: LocationName, game: Game, defs?: LocationDefinitions): L}): void {
        // Only register locations that haven't been registered yet to prevent overwriting special locations
        if (!(name in locations)) {
            locations[name] = new type(name, game, locationDefinitions);
        }
    }

    // Initialze rooms with special behavior first first
    initializeLocation("FRONT_TOWER", SafeLocation);
    initializeLocation("DEEP_CELLAR", DeepCellarLocation);
    initializeLocation("DUSTY_ROOM", DustyRoomLocation);
    initializeLocation("CELLAR", CellarLocation);
    initializeLocation("SPOOKY_ROOM", SpookyRoomLocation);
    initializeLocation("TROPHY_ROOM", TrophyRoomLocation);
    initializeLocation("UPPER_GALLERY", UpperGalleryLocation);
    initializeLocation("HALL_WITH_LOCKED_DOOR", PhoneLocation);

    // Initialize remaining rooms
    for (const name of Object.keys(locationDefinitions)) {
        initializeLocation(name as LocationName, Location);
    }

    return locations;
}
