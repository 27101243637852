import { Direction } from "../Direction";
import { Location } from "./Location";

export class UpperGalleryLocation extends Location {
    canLeave(d: Direction, to: Location): string[] {
        if (this.has("GHOSTS")) {
            this._game.attackedByGhosts = true;
            this._game.attackCount++;
            this._game.damageSanity(50);

            if (to.id !== "COBWEBBY_ROOM") {
                return this._("cant_go_attacked_by_ghosts");
            }
        }
        return [];
    }

    onLeave(d: Direction, to: Location): string[] {
        if (this.has("GHOSTS") && to.id === "COBWEBBY_ROOM")
            return this._("go_attacked_by_ghosts", { direction: d });

        return [];
    }
}
