import { D } from "../Direction";
import { Entity } from "./Entity";

export class AmuletEntity extends Entity {
    take(removeFromLocation?: boolean): string[] {
        // Allow the player to descend the earthen staircase
        this._game.locations["EARTHEN_STAIRCASE"]?.connect(D, "EARTHEN_HOLE");

        return super.take(removeFromLocation);
    }
}