import { Entity } from "./Entity";
import { PhoneEntity } from "./PhoneEntity";

export class WireEntity extends Entity {
    useOn(other: Entity): string[] {
        if (other.id === "PHONE") {
            const phone = other as PhoneEntity;
            phone.wired = true;
            this.discard();

            return this._("use_on_phone");
        }

        return super.useOn(other);
    }
}