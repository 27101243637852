import { Entity } from "./Entity";
import { E } from "../Direction";
import { CauldronEntity } from "./CauldronEntity";
import { CoalEntity } from "./CoalEntity";

export class LighterEntity extends Entity {
    useOn(other: Entity): string[] {
        // Light the cobwebs on fire
        if (this._game.currentLocation.id === "COBWEBBY_ROOM" && other.id === "WEB") {
            this._game.currentLocation.remove("WEB");
            this._game.currentLocation.connect(E, "SPOOKY_ROOM");
            return this._("use_on_cobwebs", other);
        }

        // Light the coals on fire
        if (this._game.currentLocation.id === "REAR_TURRET_ROOM" && other.id === "COALS") {
            const coals = other as CoalEntity;
            coals.heated = true;

            const cauldron = this._game.currentLocation.get("CAULDRON")! as CauldronEntity;
            cauldron.heated = true;

            return this._("use_on_coals", other);
        }

        return super.useOn(other);
    }
}
