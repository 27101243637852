import { Entity } from "./Entity";
import { LocationName } from "../location/Locations";

export class CameraEntity extends Entity {
    fragmentsFound: {
        [key in LocationName]?: boolean;
    } = {};

    useOn(other: Entity): string[] {
        if (this === other || other.id === "IPHONE" || other.id === "FLASHLIGHT")
            return this._("use_on_self", other);

        // Room-based actions
        if (this._game.currentLocation.id === "SPLIT_TREE" && other.id === "TREE") {
            this._game.foundTreehouse = true;
            return this._("use_on_treehouse");
        }

        // Fragment actions
        // Fragment handling - 1
        if (this._game.currentLocation.id === "VERANDA" && other.id === "TABLE_MAP") {
            if (!this.fragmentsFound["VERANDA"]) {
                this._game.recordProgress();
                this.fragmentsFound["VERANDA"] = true;
                return [
                    ...this._("use_on_table_top", other),
                    ...this._("use_on_fragment", other),
                ];
            } else {
                return this._("use_on_fragment_again");
            }
        }

        // Fragment handling - 2
        if (this._game.currentLocation.id === "SPOOKY_ROOM" && this._game.inventory.has("PAINTING") && other.id === "MURAL") {
            if (!this.fragmentsFound["SPOOKY_ROOM"]) {
                this._game.recordProgress();
                this.fragmentsFound["SPOOKY_ROOM"] = true;
                return [
                    ...this._("use_on_mural", other),
                    ...this._("use_on_fragment", other),
                ];
            } else {
                return this._("use_on_fragment_again");
            }
        }

        // Fragment handling - 3
        if (this._game.currentLocation.id === "REAR_TURRET_ROOM" && this._game.hallucinating && other.id === "HALLUCINATION_MAP") {
            if (!this.fragmentsFound["REAR_TURRET_ROOM"]) {
                this._game.recordProgress();
                this.fragmentsFound["REAR_TURRET_ROOM"] = true;
                return [
                    ...this._("use_on_hallucination", other),
                    ...this._("use_on_fragment", other),
                ];
            } else {
                return this._("use_on_fragment_again");
            }
        }

        // Fragment handling - 4
        if (other.id === "TABLET") {
            if (!this.fragmentsFound["HALL_WITH_LOCKED_DOOR"]) {
                this._game.recordProgress();
                this.fragmentsFound["HALL_WITH_LOCKED_DOOR"] = true;
                other.discard();
                return [
                    ...this._("use_on_tablet", other),
                    ...this._("use_on_fragment", other),
                ];
            } else {
                return this._("use_on_fragment_again");
            }
        }

        // Fragment handling - 5
        if (this._game.currentLocation.id === "TREEHOUSE" && other.id === "TREEHOUSE_MAP") {
            if (!this.fragmentsFound["TREEHOUSE"]) {
                this._game.recordProgress();
                this.fragmentsFound["TREEHOUSE"] = true;
                return [
                    ...this._("use_on_treehouse_map", other),
                    ...this._("use_on_fragment", other),
                ];
            } else {
                return this._("use_on_fragment_again");
            }
        }

        // Fragment handling - 6
        if (this._game.currentLocation.id === "EARTHEN_HOLE" && other.id === "EARTHEN_HOLE_MAP") {
            if (!this.fragmentsFound["EARTHEN_HOLE"]) {
                this._game.recordProgress();
                this.fragmentsFound["EARTHEN_HOLE"] = true;
                return [
                    ...this._("use_on_earthen_hole_map", other),
                    ...this._("use_on_fragment", other),
                ];
            } else {
                return this._("use_on_fragment_again");
            }
        }

        return this._("use_on_nothing");
    }
}
