import { Direction, U } from "../Direction";
import { Location } from "./Location";

// Location implementations
export class DeepCellarLocation extends Location {
    onEnter(d: Direction, from: Location): string[] {
        if (from.id === "CELLAR") {
            this._game.foundCellarLadder = true;
            this.connect(U, "CELLAR");
        }

        return [];
    }
}
