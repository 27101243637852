import { Entity } from "./Entity";
import { D } from "../Direction";

export class PaintingEntity extends Entity {
    take(removeFromLocation?: boolean): string[] {
        // Add additional aliases to make the objective easier after the painting is removed
        const mural = this._game.currentLocation.get("MURAL")!;
        mural.aliases = ["MAP", "FRAGMENT"];
        this._game.currentLocation.store(mural);

        // Establish link to the deep cellar
        this._game.currentLocation.connect(D, "DEEP_CELLAR");

        // Take fall damage
        this._game.damageSanity(40);

        // Take the painting, display the event, and travel to the deep, dark hole
        return [
            ...super.take(removeFromLocation),
            ...this._("on_carry_event"),
            ...this._game.go(D),
        ];
    }
}
