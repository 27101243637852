import { Direction } from "../Direction";
import { Location } from "./Location";

export class SpookyRoomLocation extends Location {
    onLeave(d: Direction, to: Location): string[] {
        if (to.id === "DEEP_CELLAR" && !this._game.fallenFromSpookyRoom) {
            this._game.fallenFromSpookyRoom = true;
            // Skip the travel dialogue when falling from the room above the first time
            return [" "];
        }

        return [];
    }
}
