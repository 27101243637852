import { Entity } from "./Entity";

// Ghosts and Bats
export class VacuumEntity extends Entity {
    powered: boolean = false;
    full: boolean = false;

    useOn(other: Entity): string[] {
        // Try to power up
        if (other.id === "BATTERY") {
            this.powered = true;
            other.hidden = true;
            other.discard();
            return this._("use_on_battery");
        }

        // Check for power first
        if (this.powered) {
            // Kill the ghosts
            if (other.id === "GHOSTS") {
                this.full = true;
                this.carryable = false;
                this.removable = false;
                this.store();
                this._game.currentLocation.remove("GHOSTS");
                return this._("use_on_ghosts");
            }
            return super.useOn(other);
        } else {
            return this._("use_on_no_power");
        }
    }
}
