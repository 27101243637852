import { Direction } from "../Direction";
import { Location } from "./Location";

export class TrophyRoomLocation extends Location {
    onLeave(d: Direction, to: Location): string[] {
        if (to.id === "CELLAR" && !to.has("SAND")) {
            this._game.slippedOnStairs = true;
            this._game.damageSanity(10);
            return this._("fall", { direction: d }, "CELLAR");
        }
        return [];
    }
}
