import { Entity } from "./Entity";
import { RopeEntity } from "./RopeEntity";

export class HookEntity extends Entity {
    useOn(other: Entity): string[] {
        if (other.id === "ROPE") {
            const rope = other as RopeEntity;
            rope.hasHook = true;
            this.discard();
            return this._("use_on_rope", other);
        }

        return super.useOn(other);
    }
}