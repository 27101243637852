export const enum Direction {
    North = "NORTH",
    South = "SOUTH",
    East = "EAST",
    West = "WEST",
    Up = "UP",
    Down = "DOWN",
}

// Aliases to cut down on verbosity
export const N = Direction.North;
export const S = Direction.South;
export const E = Direction.East;
export const W = Direction.West;
export const U = Direction.Up;
export const D = Direction.Down;

export function directionFromString(d: string) {
    switch (d) {
        case "N":
        case "NORTH":
            return N;
        case "S":
        case "SOUTH":
            return S;
        case "E":
        case "EAST":
            return E;
        case "W":
        case "WEST":
            return W;
        case "U":
        case "UP":
            return U;
        case "D":
        case "DOWN":
            return D;
        default:
            throw new Error(`Unknown direction for ${d}`);
    }
}

// Valid directions
export const Directions = [N, S, E, W, U, D] as const;

export const opposite = (d: Direction) => {
    switch (d) {
        case N: return S;
        case S: return N;
        case E: return W;
        case W: return E;
        case U: return D;
        case D: return U;
    }
}
